<template>
  <div>
    <!-- bg -->
    <div class="bg_gray"></div>
    <!-- <headbox title="物流追踪" bgColor="#fff" isborder="true"></headbox> -->
    <div class="centerbox">
      <van-tabs class="tab_title" @click="getlist">
        <van-tab title="全部">
          <!-- 全部 -->
          <div v-if="isloaddata">
            <div
              class="system_msg"
              v-for="(items, index) in listdata.content"
              :key="index"
            >
              <div class="msgbox">
                <h2 class="flex-between h2">
                  <div>
                    顺丰单号：{{ items.expressNo ? items.expressNo : "-" }}
                  </div>
                  <div class="statusbox">
                    {{ items.insuranceStatus == "NONE" ? "待投保" : "已投保" }}
                  </div>
                </h2>
                <div class="cityflex rel">
                  <img :src="arrowicon" alt="" class="arrowimg" />
                  <div>
                    {{ items.fromCity || " 未知 " }}
                  </div>
                  <b class="arrowbox">
                    <i>{{
                      items.status == "TRANSPORT"
                        ? "运输中"
                        : items.status == "WAIT_SEND"
                        ? "待取件"
                        : items.status == "REFUSE"
                        ?'已拒绝'
                        : items.status == "CANCEL"
                        ?'已取消'
                        :items.status == "SIGNED"
                        ?'已签收'
                        :'待审核'
                    }}</i>
                  </b>
                  <div>
                    {{ items.toCity }}
                  </div>
                </div>
                <div class="flex">
                  <div>下单时间：</div>
                  <div v-if="items.userOrderTime">{{ items.expressTime | formatDate_ }}</div>
                </div>
                <div class="flexs endflex">
                  <div class="gosendproduct" v-if="items.insuranceStatus === 'NONE' && (items.status === 'WAIT_SEND' || items.status === 'TRANSPORT') " >
                    <router-link
                      :to="{
                        name: 'claimedit',
                        query: { pid: items.expressNo ? items.expressNo : '' }
                      }"
                      >去投保</router-link>
                  </div>
                  <div class="gosendproduct" v-else style="background: #f7cdcd">
                    去投保
                  </div>
                  <div class="canclebox">
                    <router-link
                      :to="{
                        name: 'logisticsDetails',
                        query: { id: items.orderExpressNo }
                      }"
                      >物流详情</router-link
                    >
                  </div>

                  <div class="canclebox" @click="cancelOrder(items.orderExpressNo)" v-if="items.status === 'WAIT_SEND'">取消</div>
                  <div class="canclebox" @click="deleteOrder(items.orderExpressNo)" v-if="items.status === 'SIGNED' || items.status === 'CANCEL'">删除</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 无数据 -->
          <div v-else>
        <van-empty
           class="custom-image"
           :image="require('@/assets/images/pic_no information@2x.png')"
           description="暂无数据!"
         />
          </div>
        </van-tab>
        <van-tab title="待取件">
          <!-- 待取件 -->
          <div v-if="isloaddata">
            <div
              class="system_msg"
              v-for="(items, index) in listdata.content"
              :key="index"
            >
              <div class="msgbox">
                <h2 class="flex-between h2">
                  <div>
                    顺丰单号：{{ items.expressNo ? items.expressNo : "-" }}
                  </div>
                  <div class="statusbox">
                    {{ items.insuranceStatus == "NONE" ? "待投保" : "已投保" }}
                  </div>
                </h2>
                <div class="cityflex">
                  <div>
                    {{ items.fromCity }}
                  </div>
                  <b class="arrowbox">
                    <img src="@/assets/images/icon_yunsong.png" alt="" />
                    <i>待取件</i>
                  </b>
                  <div>
                    {{ items.toCity }}
                  </div>
                </div>
                <div class="flex">
                  <div>下单时间：</div>
                  <div v-if="items.userOrderTime">{{ items.expressTime | formatDate_ }}</div>
                </div>
                <div class="flexs endflex">
                  <div class="gosendproduct" v-if="items.insuranceStatus === 'NONE' && (items.status === 'WAIT_SEND' || items.status === 'TRANSPORT') " >
                    <router-link :to="{ name: 'claimedit' }"
                      >去投保</router-link
                    >
                  </div>
                  <div class="gosendproduct" v-else style="background: #f7cdcd">
                    去投保
                  </div>
                  <div class="canclebox">
                    <router-link
                      :to="{
                        name: 'logisticsDetails',
                        query: { id: items.orderExpressNo }
                      }"
                      >物流详情</router-link
                    >
                  </div>

                  <div class="canclebox" @click="cancelOrder(items.orderExpressNo)" v-if="items.status === 'WAIT_SEND'">取消</div>
                  <div class="canclebox" @click="deleteOrder(items.orderExpressNo)" v-if="items.status === 'SIGNED' || items.status === 'CANCEL'">删除</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 无数据 -->
          <div v-else>
          <van-empty
           class="custom-image"
           :image="require('@/assets/images/pic_no information@2x.png')"
           description="暂无数据!"
         />
          </div>
        </van-tab>
        <van-tab title="运输中">
          <!-- 运输中 -->
          <div v-if="isloaddata">
            <div
              class="system_msg"
              v-for="(items, index) in listdata.content"
              :key="index"
            >
              <div class="msgbox">
                <h2 class="flex-between h2">
                  <div>
                    顺丰单号：{{ items.expressNo ? items.expressNo : "-" }}
                  </div>
                  <div class="statusbox">
                    {{ items.insuranceStatus == "NONE" ? "待投保" : "已投保" }}
                  </div>
                </h2>
                <div class="cityflex">
                  <div>
                    {{ items.fromCity }}
                  </div>
                  <b class="arrowbox">
                    <img src="@/assets/images/arrownpx.png" alt="" />
                    <i>运输中</i>
                  </b>
                  <div>
                    {{ items.toCity }}
                  </div>
                </div>
                <div class="flex">
                  <div>下单时间：</div>
                  <div v-if="items.userOrderTime">{{ items.expressTime | formatDate_ }}</div>
                </div>
                <div class="flexs endflex">
                  <div class="gosendproduct" v-if="items.insuranceStatus === 'NONE' && (items.status === 'WAIT_SEND' || items.status === 'TRANSPORT') " >
                    <router-link :to="{ name: 'claimedit' }"
                      >去投保</router-link
                    >
                  </div>
                  <div class="gosendproduct" v-else style="background: #f7cdcd">
                    去投保
                  </div>
                  <div class="canclebox">
                    <router-link
                      :to="{
                        name: 'logisticsDetails',
                        query: { id: items.orderExpressNo }
                      }"
                      >物流详情</router-link
                    >
                  </div>

                  <div class="canclebox" @click="cancelOrder(items.orderExpressNo)" v-if="items.status === 'WAIT_SEND'">取消</div>
                  <div class="canclebox" @click="deleteOrder(items.orderExpressNo)" v-if="items.status === 'SIGNED' || items.status === 'CANCEL'">删除</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 无数据 -->
          <div v-else>
          <van-empty
           class="custom-image"
           :image="require('@/assets/images/pic_no information@2x.png')"
           description="暂无数据!"
         />
          </div>
        </van-tab>
        <van-tab title="已签收">
          <!-- 已签收 -->
          <div v-if="isloaddata">
            <div
              class="system_msg"
              v-for="(items, index) in listdata.content"
              :key="index"
            >
              <div class="msgbox">
                <h2 class="flex-between h2">
                  <div>
                    顺丰单号：{{ items.expressNo ? items.expressNo : "-" }}
                  </div>
                  <div class="statusbox">
                    {{ items.insuranceStatus == "NONE" ? "待投保" : "已投保" }}
                  </div>
                </h2>
                <div class="cityflex">
                  <div>
                    {{ items.fromCity }}
                  </div>
                  <b class="arrowbox">
                    <img src="@/assets/images/arrownpx.png" alt="" />
                    <i>已签收</i>
                  </b>
                  <div>
                    {{ items.toCity }}
                  </div>
                </div>
                <div class="flex">
                  <div>下单时间：</div>
                  <div v-if="items.userOrderTime">{{ items.signTime | formatDate_ }}</div>
                </div>
                <div class="flexs endflex">
                  <div class="gosendproduct" v-if="items.insuranceStatus === 'NONE' && (items.status === 'WAIT_SEND' || items.status === 'TRANSPORT') " >
                    <router-link :to="{ name: 'claimedit' }"
                      >去投保</router-link
                    >
                  </div>
                  <div class="gosendproduct" v-else style="background: #f7cdcd">
                    去投保
                  </div>
                  <div class="canclebox">
                    <router-link
                      :to="{
                        name: 'logisticsDetails',
                        query: { id: items.orderExpressNo }
                      }"
                      >物流详情</router-link
                    >
                  </div>

                  <div class="canclebox" @click="cancelOrder(items.orderExpressNo)" v-if="items.status === 'WAIT_SEND'">取消</div>
                  <div class="canclebox" @click="deleteOrder(items.orderExpressNo)" v-if="items.status === 'SIGNED' || items.status === 'CANCEL'">删除</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 无数据 -->
          <div v-else>
       <van-empty
           class="custom-image"
           :image="require('@/assets/images/pic_no information@2x.png')"
           description="暂无数据!"
         />
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import { formatDate_ } from "@/utils/filters";
// import headbox from "@/components/head.vue";
// 保险单列表
import * as expressApi from "@/utils/api";
export default {
  data() {
    return {
      arrowicon: require("@/assets/images/icon_yunsong.png"),
      listdata: {},
      isloaddata: false,
      type: ""
    };
  },
  mounted() {
    // 待审核, 显示待取件，同时不显示投保状态 => WAIT_VERIFY,
    // 已拒绝 => REFUSE,
    // 待取件 => WAIT_SEND,
    // 运输中 => TRANSPORT,
    // 已签收 => SIGNED,
    // 已取消 => CANCEL,
    // 其他 => OTHER
    this.isloadingdata();
  },
  filters: {
    formatDate_
  },
  methods: {
    isloadingdata(type) {
      this.listdata = "";
      const parmstype = type || "";
      expressApi.myexpress(parmstype).then(res => {
        this.listdata = res;
        console.log(res);
        if (this.listdata.content.length >= 1) {
          this.isloaddata = true;
        }else{
          this.isloaddata = false;

        }
      });
    },
    getlist(name, title) {
      let type = "";
      if (title == "待取件") {
        type = "WAIT_SEND";
      } else if (title == "运输中") {
        type = "TRANSPORT";
      } else if (title == "已签收") {
        type = "SIGNED";
      } else if (title == "全部") {
        type = "";
      }
      this.type = type
      this.isloadingdata(type);
    },
    cancelOrder(expressNo) {
      expressApi.cancelExpress(expressNo).then(res => {
        this.$toast.success({
          message: "取消成功",
          duration: 1500
        });
        setTimeout(() => {
          this.isloadingdata(this.type);
        }, 1500);
      });
    },
    deleteOrder(expressNo) {
      expressApi.deleteExpress(expressNo).then(res => {
        this.$toast.success({
          message: "删除成功",
          duration: 1500
        });
        setTimeout(() => {
          this.isloadingdata(this.type);
        }, 1500);
      });
    }
  },
  // components: {
  //   headbox
  // }
};
</script>
<style lang="scss" scoped="true">
.arrowimg {
  width: 18%;
  position: absolute;
  left: 41%;
  top: 40%;
  // display: block;
}
.arrowbox {
  padding-top: 30px;
  text-align: center;
  i {
    font-style: normal;
  }
  img {
    width: 168px;
    height: 30px;
    display: block;
  }
}
::v-deep .van-tabs__nav {
  background: #f5f5f5;
  .van-tab {
    padding: 10px 0px;
  }
}
::v-deep .van-tab__text--ellipsis {
  height: 30px;
  line-height: 30px;
  font-size: 28px;
}
::v-deep .van-tab--active .van-tab__text--ellipsis {
  color: #333333;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: auto !important;
}
::v-deep .van-tabs__nav {
  height: auto;
}
::v-deep .van-tabs__line {
  bottom: 1px !important;
  background-color: #ffb606;
}
::v-deep .van-tabs__content{
  padding: 10px 20px 20px !important;
}
.canclebox {
  border: 1px solid #ff2d2e;
  color: #ff2d2e;
  width: 160px;
  height: 64px;
  border-radius: 30px;
  text-align: center;
  line-height: 64px;
}
.flex {
  align-items: baseline;
}
.endflex {
  border-top: 1px solid #e6e6e6;
  padding-top: 20px;
  justify-content: flex-end;
  div {
    margin-left: 20px;
  }
}
.cityflex {
  position: relative;
  font-weight: bold;
  justify-content: center;
  display: flex;
  font-size: 36px;
  color: #333333;
  div {
    margin: 40px 50px 60px;
  }
  b {
    font-size: 26px;
  }
}
.gosendproduct {
  background: #ff2d2e;
  color: #fff;
  border-radius: 32px;
  width: 160px;
  height: 64px;
  text-align: center;
  line-height: 64px;
  font-size: 28px;
}
.flexs {
  display: flex;
}
.bg_gray {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #f5f5f5;
}
.centerbox {
  position: relative;
  z-index: 2;
}
.statusbox {
  color: #fc4e7f;
  font-size: 28px;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: normal;
}
.onstatusbox {
  color: #fbac1e;
  font-size: 28px;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: normal;
}
.overstatusbox {
  color: #999999;
  background: #f0f0f0;
  font-size: 28px;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: normal;
}
.flex-between {
  align-items: center;
  i {
    font-size: 48px;
    color: #cccccc;
  }
}

.yellowmin {
  color: #ffb606;
  border: 1px solid #ffb606;
  border-radius: 30px;
  font-size: 28px;
  width: 160px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  // margin-bottom: 30px;
}
.msgbox {
  background: #fff;
  padding: 0 30px 30px;
  width: 690px;
  font-size: 28ox;
  border-radius: 12px;
  box-shadow: 0 0 0 3px #ececec;
  margin: 25px auto 30px;

  h2 {
    font-size: 28px;
    margin-bottom: 30px;
    padding-top: 30px;
  }
  .flex {
    margin-bottom: 30px;
    div:nth-child(1) {
      color: #cccccc;
    }
    div:nth-child(2) {
      color: #333333;
    }
  }
}
.tab_title {
  // background: #F7F7F7;
  color: #999999;
  font-size: 26px;
  span {
    padding: 20px 0px 10px;
  }
  .active {
    position: relative;
    // color:#FFB606;
    &::after {
      content: "";
      width: 100%;
      height: 5px;
      border-radius: 4px;
      background: #ffb606;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
.iconbox {
  width: 125px;
}
.titlebox {
  font-size: 30px;
}
.msg_list {
  padding: 30px 0;
  border-bottom: 1px solid #cdcdcd;
  .time {
    position: absolute;
    right: 0;
    color: #b3b3b3;
    font-size: 24px;
    font-weight: normal;
    top: 35px;
    right: 30px;
  }
}
.details {
  font-size: 24px;
  margin-top: 15px;
  color: #b3b3b3;
}
.custom-image{
  height: 100%;
}
::v-deep{
      .van-empty__image{
        width: 420px;
        height: 260px;
    }
}
</style>
